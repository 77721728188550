<template>
  <VuepalLink :to="url?.path" class="teaser">
    <figure class="teaser-image">
      <MediaImage
        v-if="media"
        v-bind="media"
        :image-style="index === 'first' ? articleTeaserLarge : articleTeaser"
        class="image is-square is-rounded"
      />
    </figure>
    <h4 class="title is-5">{{ title }}</h4>
    <div v-if="lead" class="teaser-text" v-html="lead" />
  </VuepalLink>
</template>

<script lang="ts" setup>
import type { NodeArticleTeaserFragment } from '#graphql-operations'

defineProps<{
  nid?: number
  title?: string
  lead?: string
  url?: NodeArticleTeaserFragment['url']
  media?: NodeArticleTeaserFragment['media']
  index?: string
}>()

const articleTeaserLarge = defineImageStyle({
  type: 'sizes',
  aspectRatio: 1,
  sizes: {
    xs: 255,
    sm: 255,
    md: 465,
    lg: 540,
    xl: 540,
  },
})

const articleTeaser = defineImageStyle({
  type: 'sizes',
  aspectRatio: 1,
  sizes: {
    xs: 255,
  },
})
</script>

<style lang="scss">
.teaser {
  .title {
    hyphens: auto;
  }
}
</style>
